<template>
    <div>
        <v-divider></v-divider>
        <v-list-item class="align-start justify-start">
            <v-list-item-avatar>
                <v-icon v-html="icon" :x-large="isMobile" @click="call()"></v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="align-self-start">
                <v-list-item-title>
                    {{ title }}
                </v-list-item-title>
                <v-list-item-subtitle v-html="fullName">
                </v-list-item-subtitle>
                <v-list-item-subtitle  v-if="isMobile">
                {{ phoneNumber | phone }}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content  class="align-self-start pl-1" v-if="!isMobile">
                <v-list-item-title class="text-right">
                    {{ phoneNumber | phone }}
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="$can('manage', 'EventContact') || event.eventCategoryId === 17">
                <contact-button :icon="'more_vert'" :eventId="eventId" :contact="$props" @onClose="onClose"></contact-button>
            </v-list-item-action>
        </v-list-item>
    </div>
</template>

<script>

    import contactButton from './ContactButton'
    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'sidebar-event-contact',
        components: {
            contactButton,
        },
        data() {
            return {
                menu: false,
            }
        },
        props: {
            id: [Number, null],
            event: Object,
            eventId: Number,
            contactTypeId: Number,
            contactTypeName: String,
            deviceId: Number,
            deviceCode: {
                type: String,
                default: '',
            },
            prefix: String,
            title: {
                type: String,
                default: '&nbsp;',
            },
            name: {
                type: String,
                default: '&nbsp;',
            },
            suffix: String,
            number: Number,
            code: String,
            type: String,
            icon: {
                type: String,
                default: 'person',
            },
            custom: Number,
            persistent: Number,
        },
        computed: {
            ...mapGetters('contactTypes', {
                contactTypeOptions: 'options',
            }),
            fullName() {
                return (this.deviceCode && this.deviceCode.length > 0 ? this.deviceCode + ' ' : '') + this.name
            },
            phoneNumber() {
                return this.number ? this.number : ''
            },
            ...mapState({
                isMobile: state => state.isMobile,
            }),
        },
        methods: {
            call() {
                window.location.href = `tel:${this.phoneNumber}`;
            },
            onClose() {
                this.menu = false;
            },
        }
    }
</script>
